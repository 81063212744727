.community-background {
    .points-wrap {
        // 标点
        .point {
            height: 38px;
            font-size: 16px;
        }
        .content {
            &.top {
                transform: translateX(-50%) translateY(calc(-100% - 28px));
                position: absolute;
            }
        }
    }
}
