
.modal {
    background: rgba(1, 24, 54, 0.97);
    box-shadow: inset 0px 1px 40px 0px #006091;
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    .modalTitle {
        padding-right: 16px;
        border-radius: 8px 8px 0px 0px;
        font-size: 14px;
        font-weight: bold;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #074e9e;
        position: relative;

        .titleText {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;

            >div {
                padding-left: 16px;
                display: flex;
                align-items: center;
                position: relative;

                span {
                    background: linear-gradient(180deg, #ffffff 0%, #7ee7ff 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                img {
                    margin-left: 8px;
                    transform: rotate(180deg);
                }

                .selected {
                    transform: rotate(0deg);
                }

                .selectModal {
                    width: 128px;
                    background: rgba(5, 43, 62, 0.8);
                    border-radius: 8px;
                    position: absolute;
                    left: 0px;
                    top: 36px;
                    -webkit-text-fill-color: white;

                    li {
                        line-height: 48px;
                        border: 1px solid rgba(37, 167, 255, 0.6);
                        font-size: 14px;
                        padding-left: 16px;
                        font-weight: 400;
                        cursor: pointer;

                        &:nth-child(1) {
                            border-radius: 8px 8px 0 0;
                        }

                        &:nth-last-child(1) {
                            border-radius: 0 0 8px 8px;
                        }

                        &:hover {
                            box-shadow: inset 0px 0px 16px 0px rgba(40, 167, 255, 0.8);
                        }
                    }

                    .choosed {
                        background: #074e9e;
                        box-shadow: inset 0px 0px 16px 0px rgba(37, 167, 255, 0.8);
                    }
                }
            }
        }

        .dateChoose {
            display: flex;
            justify-content: space-between;
            position: absolute;
            right: 0;
            top: -46px;

            span {
                width: 64px;
                line-height: 36px;
                text-align: center;
                font-size: 16px;
                cursor: pointer;
                border: 1px solid rgba(37, 167, 255, 0.6);
                background: #074e9e;

                &:nth-child(1) {
                    border-radius: 8px 0px 0px 8px;
                }

                &:nth-last-of-type(1) {
                    border-radius: 0px 8px 8px 0px;
                }
            }

            .active {
                background: #074e9e;
                box-shadow: inset 0px 0px 16px 0px rgba(37, 167, 255, 0.8);
                -webkit-text-fill-color: white;
            }
        }

        img {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    .children {
        flex: 1;
    }
}