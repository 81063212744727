.communityInfo {

    .icon {
        width: 24px;
        height: 24px;
    }

    .title {
        line-height: 27px;

        .name {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .subTitle {
        width: 156px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .allNum,
    .num {
        background-image: linear-gradient(180deg, #FDFFFF 0%, #6AB3F5 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .allNum {

        span {
            line-height: 0;
        }
    }

    .trend {
        width: 16px;
        height: 16px;
    }

    .letter-s {
        letter-spacing: 7px;
    }

    .warnings {
        .name{
            color: #FFFFFF;
            background: linear-gradient(180deg, #FFFFFF 0%, #25A6FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
        }
    }

    .warning {
        &:nth-of-type(1){
            .con{
                border-right: 1px solid rgba(216, 216, 216, 0.6400);
            }
        }
        .num {
            >div{
                background-image: linear-gradient(180deg, #FFFFFF 0%, #FF7167 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
    
                &.processed{
                    background-image: linear-gradient(180deg, #FDFFFF 0%, #6AB3F5 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                // span {
                //     line-height: 0;
                // }
            }

        }
    }

    .antiepidemic {
        span {
            line-height: 0;
            background-image: linear-gradient(180deg,#fdffff,#6ab3f5);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

}

.warningLists {
    background: rgba(1, 24, 54, 0.97);
    box-shadow: inset 0px 1px 40px 0px #006091;
    border-radius: 8px;
    backdrop-filter: blur(2px);

    &.hide {
        height: 0;
    }

    .closeIcon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 23px;
        right: 16px;
        cursor: pointer;

        &.hide {
            transform: rotateX(180deg);
        }
    }

    .title {
        line-height: 32px;
        background: #074E9E;
        border-radius: 8px 8px 0px 0px;
        backdrop-filter: blur(4px);

    }

    .list {
        overflow-y: auto;
        height: calc(88vh - 72px);

        .item {
            background: linear-gradient(360deg, rgba(34, 62, 97, 0.8) 0%, rgba(25, 37, 62, 0.08) 100%);
            border-radius: 4px;
            border: 1px solid rgba(11, 192, 224, 0.24);
            overflow: hidden;


            .type {

                .people,
                .self,
                .event {
                    line-height: 28px;
                    border-radius: 2px;
                }

                .people {
                    background: #FF711F;
                }

                .self {
                    background: #FC5C5C;
                }

                .event {
                    background: #FEB903;
                }
                .name {
                    color: #FFFFFF;
                    background: linear-gradient(180deg, #FFFFFF 0%, #7EE7FF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}