.resident-info {
    background: rgba(1, 24, 54, 0.97);
    box-shadow: inset 0px 1px 40px 0px #006091;
    border-radius: 8px;
    backdrop-filter: blur(2px);
    width: 48vw;
    height: 86vh;
    position: fixed;
    padding: 28px 24px;
    top: 0;
    right: 0;
    z-index: 11;
    .report-title {
        margin-bottom: 24px;
        .title-text {
            font-size: 22px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            color: #ffffff;
            line-height: 30px;
            background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-right: 24px;
        }
    }
    .close-img {
        position: absolute;
        top: 18px;
        right: 16px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .modal-content {
        height: 78vh;
        overflow-y: auto;
        .record-item {
            margin-bottom: 40px;
            position: relative;
            .item-tail {
                position: absolute;
                top: 20px;
                left: 9px;
                height: calc(100% + 21px);
                border-left: 1px solid #5ce1ff;
            }
            .record-time {
                margin-bottom: 16px;
                .time {
                    font-size: 18px;
                    font-weight: 500;
                    color: #5ce1ff;
                }
                .dot-img {
                    margin-right: 16px;
                    width: 20px;
                    display: inline-block;
                }
                .sumarys {
                    margin-left: 16px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                }
            }
            .record-content {
                font-size: 16px;
                color: #ffffff;
                margin-left: 38px;
                .record-row {
                    margin-bottom: 8px;
                }
                .checkUser {
                    font-size: 14px;
                    font-weight: 600;
                    color: #d0deee;
                    line-height: 20px;
                    margin-left: 16px;
                }
            }
        }
        /*滚动条样式*/
        &::-webkit-scrollbar {
            width: 4px;
            /*height: 4px;*/
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
            background: rgba(255, 255, 255, 0.6);
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
        }
    }
}
