.log-container {
    width: 932px;
    z-index: 201;
    max-height: 822px;
    border-radius: 8px;
    background: rgba(1, 24, 54, 0.97);
    box-shadow: inset 0px 1px 40px 0px #006091;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 24px;
    // overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 33px;

        >div {
            display: flex;
            align-items: center;
            font-size: 22px;
            line-height: 30px;
            background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: bold;
            margin-right: 16px;

            span {
                &:nth-child(1) {
                    margin-right: 16px;
                }
            }
        }

        img {
            height: 24px;
            width: 24px;
            margin-left: 16px;
            cursor: pointer;
        }

        .tag {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            height: 28px;
            width: 58px;
            text-align: center;
            border-radius: 2px;
        }

        .tag1 {
            -webkit-text-fill-color: #feb903;
            background-color: rgba(254, 185, 3, 0.2);
        }

        .tag2 {
            background: rgba(92, 255, 181, 0.2);
            -webkit-text-fill-color: #10df9d;
        }

        .tag3 {
            -webkit-text-fill-color: #26a6ff;
            background-color: rgba(128, 128, 128, 0.2);
        }

        .tag4 {
            -webkit-text-fill-color: white;
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    .hideScall {
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0 !important;
            display: none;
        }
    }

    .report-time {
        display: flex;

        .itemImg {
            display: flex;
            flex-direction: column;

            img {
                height: 20px;
                width: 20px;
                margin-right: 16px;
            }

            .line {
                background-color: #5ce1ff;
                width: 1px;
                flex: 1;
                margin-left: 9px;
            }
        }

        .itemTitle {
            flex: 1;

            .logtitle {
                font-size: 24px;
                font-weight: 500;
                color: #FFFFFF;
                padding: 16px;
                background: rgba(51, 139, 255, 0.24);
                border-radius: 4px;
            }

            .logsmsg {
                padding: 16px;
                background: linear-gradient(360deg, rgba(34, 62, 97, 0.8) 0%, rgba(25, 37, 62, 0.08) 100%);
                border-radius: 4px;
            }


            .itemCenter {
                padding: 16px;
                background: linear-gradient(360deg, rgba(34, 62, 97, 0.8) 0%, rgba(25, 37, 62, 0.08) 100%);
                border-radius: 4px;
                display: flex;
                flex-wrap: wrap;

                >div {
                    margin-bottom: 12px;
                    display: flex;

                    .item-label {
                        min-width: 70px;
                        max-width: 130px;
                    }

                    .img {
                        display: flex;
                        flex-wrap: wrap;

                        img {
                            width: 96px;
                            height: 96px;
                            margin-right: 6px;
                            margin-bottom: 2px;
                        }
                    }
                }
            }

            .logs-content {
                padding: 16px;
                background: linear-gradient(360deg, rgba(34, 62, 97, 0.8) 0%, rgba(25, 37, 62, 0.08) 100%);
                border-radius: 4px;
                &:not(:first-of-type){
                    margin-top: 20px;
                }

                >div {
                    margin-bottom: 12px;
                    display: flex;

                    .item-label {
                        min-width: 70px;
                        max-width: 130px;
                    }
                }
            }

            .showMoreLogs {
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 24px;
                background: linear-gradient(180deg, #FDFFFF 0%, #6AB3F5 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}

.ant-image-preview-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}