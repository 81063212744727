/* windicss layer base */
*, ::before, ::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
::moz-focus-inner {
  border-style: none;
  padding: 0;
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
body {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
img {
  border-style: solid;
  display: block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* windicss layer components */

/* windicss layer utilities */
.bg-\[rgba\(0\2c 0\2c 0\2c 0\.4\)\] {
  --tw-bg-opacity: 0.4;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-left {
  background-position: left;
}
.bg-right {
  background-position: right;
}
.bg-top {
  background-position: top;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.hidden {
  display: none;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.h-3 {
  height: 0.75rem;
}
.h-full {
  height: 100%;
}
.h-10 {
  height: 2.5rem;
}
.h-4 {
  height: 1rem;
}
.h-\[950px\] {
  height: 950px;
}
.h-\[902px\] {
  height: 902px;
}
.h-\[17px\] {
  height: 17px;
}
.h-\[963px\] {
  height: 963px;
}
.h-\[24px\] {
  height: 24px;
}
.h-\[28px\] {
  height: 28px;
}
.h-\[20px\] {
  height: 20px;
}
.text-\[16px\] {
  font-size: 16px;
  line-height: 1;
}
.leading-\[19px\] {
  line-height: 19px;
}
.leading-\[24px\] {
  line-height: 24px;
}
.leading-\[28px\] {
  line-height: 28px;
}
.leading-6 {
  line-height: 1.5rem;
}
.ml-\[4px\] {
  margin-left: 4px;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-1\.5 {
  margin-top: 0.375rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mr-\[2px\] {
  margin-right: 2px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}
.ml-16 {
  margin-left: 4rem;
}
.mt-2\.5 {
  margin-top: 0.625rem;
}
.mr-12 {
  margin-right: 3rem;
}
.mt-28 {
  margin-top: 7rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mt-14 {
  margin-top: 3.5rem;
}
.mb-5\.5 {
  margin-bottom: 1.375rem;
}
.ml-8 {
  margin-left: 2rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-18 {
  margin-top: 4.5rem;
}
.ml-12 {
  margin-left: 3rem;
}
.mr-20 {
  margin-right: 5rem;
}
.mr-50 {
  margin-right: 12.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-1\.5 {
  margin-bottom: 0.375rem;
}
.mr-\[4px\] {
  margin-right: 4px;
}
.mr-\[10px\] {
  margin-right: 10px;
}
.overflow-y-auto {
  overflow-y: auto;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pr-20 {
  padding-right: 5rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pr-16 {
  padding-right: 4rem;
}
.pb-22 {
  padding-bottom: 5.5rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pt-20 {
  padding-top: 5rem;
}
.tab {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.top-0 {
  top: 0px;
}
.left-0 {
  left: 0px;
}
.resize {
  resize: both;
}
.text-center {
  text-align: center;
}
.text-\[\#ffb900\] {
  --tw-text-opacity: 1;
  color: rgba(255, 185, 0, var(--tw-text-opacity));
}
.text-\[\#26a6ff\] {
  --tw-text-opacity: 1;
  color: rgba(38, 166, 255, var(--tw-text-opacity));
}
.text-\[\#10df9d\] {
  --tw-text-opacity: 1;
  color: rgba(16, 223, 157, var(--tw-text-opacity));
}
.content-two {
  content: "two";
}
.visible {
  visibility: visible;
}
.w-full {
  width: 100%;
}
.w-3 {
  width: 0.75rem;
}
.w-20 {
  width: 5rem;
}
.w-4 {
  width: 1rem;
}
.w-100 {
  width: 25rem;
}
.w-\[774px\] {
  width: 774px;
}
.w-\[458px\] {
  width: 458px;
}
.w-\[300px\] {
  width: 300px;
}
.w-25 {
  width: 6.25rem;
}
.w-75 {
  width: 18.75rem;
}
.w-50 {
  width: 12.5rem;
}
.w-37\.5 {
  width: 9.375rem;
}
.w-30 {
  width: 7.5rem;
}
.w-240 {
  width: 60rem;
}
.w-\[100px\] {
  width: 100px;
}
.w-\[17px\] {
  width: 17px;
}
.w-\[196px\] {
  width: 196px;
}
.w-\[152px\] {
  width: 152px;
}
.w-\[120px\] {
  width: 120px;
}
.w-\[128px\] {
  width: 128px;
}
.w-\[172px\] {
  width: 172px;
}
.w-\[138px\] {
  width: 138px;
}
.w-\[112px\] {
  width: 112px;
}
.w-\[225px\] {
  width: 225px;
}
.w-\[24px\] {
  width: 24px;
}
.w-\[170px\] {
  width: 170px;
}
.w-\[208px\] {
  width: 208px;
}
.w-\[160px\] {
  width: 160px;
}
.w-\[101px\] {
  width: 101px;
}
.w-\[88px\] {
  width: 88px;
}
.w-\[127px\] {
  width: 127px;
}
.w-\[95px\] {
  width: 95px;
}
.w-\[111px\] {
  width: 111px;
}
.w-\[155px\] {
  width: 155px;
}
.w-\[180px\] {
  width: 180px;
}
.w-\[50\%\] {
  width: 50%;
}
.z-index\:-1 {
  z-index: 1;
}
.z-20 {
  z-index: 20;
}
.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}
.blur\(2px\) {
  --tw-blur: blur(8px);
}
@media (min-width: 2560px) {
  .md\:w-135 {
    width: 33.75rem;
  }
  .md\:w-\[1016px\] {
    width: 1016px;
  }
  .md\:w-\[600px\] {
    width: 600px;
  }
  .md\:w-\[400px\] {
    width: 400px;
  }
  .md\:w-\[290px\] {
    width: 290px;
  }
}