.CommunityFB-modalContent {
    width: 100%;
    height: 808px;
    background: rgba(1, 24, 54, 0.97);
    box-shadow: inset 0px 1px 40px 0px #006091;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    z-index: 100;
    .modalTitle {
        padding: 0 16px;
        font-size: 14px;
        font-weight: bold;
        background-color: #074e9e;
        height: 48px;
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        img {
            height: 24px;
            width: 24px;
            cursor: pointer;
            margin-right: 12px;
        }
        div {
            width: 100%;
            display: flex;
            align-items: center;
            span {
                background: linear-gradient(180deg, #ffffff 0%, #7ee7ff 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                line-height: 48px;
            }
        }
    }
    .modalContent {
        color: #fff;
        height: 760px;
        font-size: 16px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        li {
            padding: 0 16px 0;
            height: 48px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:nth-child(even) {
                background: rgba(78, 90, 105, 0.4);
            }
            &:hover {
                box-shadow: inset 0px 0px 16px 0px rgba(40, 167, 255, 0.8);
            }
            .text-ellipesis {
                .institutionName {
                    display: flex;
                    align-items: center;
                    .infoName {
                        width: 248px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}

.community-fb-detail {
    width: 900px;
    background: rgba(2,44,99,0.97);
box-shadow: inset 0px 1px 40px 0px #006091;
    border-radius: 8px;

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #074e9e;
        border-radius: 8px 8px 0px 0px;
        height: 48px;
        padding: 0 16px;

        .content {
            height: 28px;
            font-size: 16px;
            font-family: AlibabaPuHuiTi-Medium;
            color: #ffffff;
            line-height: 28px;
            background-color: linear-gradient(360deg, rgba(40, 84, 139, 0.8) 0%, rgba(42, 65, 112, 0.08) 100%);
            span {
                &:nth-child(1) {
                    font-weight: bold;
                    background: linear-gradient(180deg, #ffffff 0%, #7ee7ff 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    > .content {
        border-radius: 4px;
        margin: 16px;
        font-size: 16px;
        background: linear-gradient(360deg, rgba(34, 62, 97, 0.8) 0%, rgba(25, 37, 62, 0.08) 100%);
        .item {
            display: flex;
            align-items: flex-start;
            font-size: 14px;
            font-family: AlibabaPuHuiTi-Regular;
            font-weight: 400;
            color: #ffffff;
            line-height: 28px;
            margin-bottom: 16px;
            span {
                &:nth-child(2) {
                    font-weight: bold;
                }
            }
            > img {
                max-height: 168px;
            }
        }
    }
    .content-two {
        border-radius: 4px;
        margin: 16px;
        padding-bottom: 24px;
        font-size: 16px;
        background: linear-gradient(360deg, rgba(34, 62, 97, 0.8) 0%, rgba(25, 37, 62, 0.08) 100%);
        .tableList {
            padding: 0 16px;
            .detail-title {
                border-radius: 4px 4px 0 0;
                background-color: #074e9e;
                height: 32px;
                span {
                    padding-left: 16px;
                    background: linear-gradient(180deg, #ffffff 0%, #26a6ff 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            ul {
                max-height: 312px;
                border-radius: 0 0 4px 4px;
                li {
                    background: #011836;
                    &:nth-child(even) {
                        background: #0c223e;
                    }
                    span {
                        color: white;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
.tag {
    height: 24px;
    width: 40px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
// 优质
.tag1 {
    background: rgba(16, 223, 157, 0.12);
    color: #10df9d;
}
// 良好
.tag2 {
    background: rgba(92, 225, 255, 0.12);
    color: #5ce1ff;
}
// 达标
.tag3 {
    background: rgba(51, 139, 255, 0.12);
    color: #67A7FB;
}
// 预警
.tag4 {
    background: rgba(254, 185, 3, 0.12);
    color: #feb903;
}
// 失信
.tag5 {
    background: rgba(255, 56, 56, 0.12);
    color: #fc5c5c;
}
