.houseInfo {
    background: rgba(1, 24, 54, 0.97);
    box-shadow: inset 0px 1px 40px 0px #006091;
    border-radius: 8px;
    backdrop-filter: blur(2px);
    width: 48vw;
    height: 950px;
    padding: 16px;
    position: fixed;
    right: calc(24vw + 40px);
    z-index: 10;

    .modal-head {
        position: relative;

        .house-name {
            font-size: 22px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: 500;
            color: #ffffff;
            background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-right: 16px;
        }

        .house-label {
            font-size: 14px;
            border-radius: 2px;
            padding: 2px 6px;
            position: relative;
            margin-right: 8px;
            top: -4px;
        }

        .label1 {
            background: rgba(255, 201, 122, 0.1);
            color: #ffc97a;
        }

        .label2 {
            background: rgba(255, 113, 31, 0.1);
            color: #ff711f;
        }

        .label3 {
            background: rgba(255, 56, 56, 0.1);
            color: #ff3838;
        }

        .label4 {
            background: rgba(255, 122, 122, 0.1);
            color: #ff7a7a;
        }

        .close-img {
            position: absolute;
            right: 0;
            top: -4px;
            cursor: pointer;
        }
    }

    .modal-tag {
        margin-top: 20px;
        margin-bottom: 16px;
        display: flex;

        >span {
            margin-right: 16px;
        }

        .jump-tag {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            display: inline-block;
            padding: 6px 13px;
            text-decoration: none;
            background: rgba(78, 90, 105, 0.3);
            border-radius: 2px;
            cursor: pointer;
        }

        .tagActive {
            background: #074e9e;
            box-shadow: inset 0px 0px 16px 0px rgba(37, 167, 255, 0.8);
            border: 1px solid rgba(37, 167, 255, 0.6);
        }
    }

    .modal-content {
        height: 810px;
        overflow-y: auto;

        .virus-tab {
            display: flex;
            margin-bottom: 12px;

            .tab-item {
                width: 112px;
                height: 32px;
                cursor: pointer;
                line-height: 32px;
                text-align: center;
                background: rgba(78, 90, 105, 0.3);
                border-radius: 2px;
                font-size: 16px;
                color: #ffffff;

                &:first-child {
                    margin-right: 12px;
                }
            }

            .tab-active {
                background: #074e9e;
                box-shadow: inset 0px 0px 16px 0px rgba(37, 167, 255, 0.8);
                border: 1px solid rgba(37, 167, 255, 0.6);
            }
        }

        .id-check {
            .vicons {
                width: 14px;
            }

            .resident-title {
                background: #074e9e;
                border-radius: 4px 4px 0px 0px;
                backdrop-filter: blur(4px);
                padding: 12px 24px;
            }

            .top-row {
                margin-bottom: 4px;

                .main-title {
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                    color: #ffffff;
                    text-shadow: 0px 2px 8px rgba(92, 225, 255, 0.4);
                }
            }

            .title-num {
                font-size: 14px;

                .yellow {
                    color: #ffc97a;
                }
            }

            .resident-list {
                font-size: 14px;
                background: rgba(78, 90, 105, 0.12);
                border-radius: 4px;
                padding: 20px 24px;

                .row-val {
                    &:first-child {
                        margin-right: 40px;
                    }
                }

                .resident-row {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .resident-phone {
                        display: inline-block;
                        width: 160px;
                    }

                    .icons {
                        width: 12px;
                        margin-left: 16px;
                        margin-right: 12px;
                    }

                    .checked {
                        margin-left: 8px;
                        line-height: 28px;
                        text-align: center;
                        display: inline-block;
                        position: relative;
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background: rgba(40, 196, 69, 0.1);
                        border: 1px solid rgba(92, 255, 181, 0.3);
                    }

                    .notChecked {
                        margin-left: 8px;
                        line-height: 28px;
                        text-align: center;
                        display: inline-block;
                        position: relative;
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background: rgba(0, 0, 0, 0.08);
                        border: 1px solid rgba(151, 151, 151, 0.4);
                    }

                    .untoday {
                        margin-left: 8px;
                        line-height: 28px;
                        text-align: center;
                        border-radius: 50%;
                        display: inline-block;
                        position: relative;
                        width: 28px;
                        height: 28px;
                        background: rgba(255, 103, 38, 0.1);
                        border: 1px solid #ff6726;
                    }

                    .check-img {
                        position: absolute;
                        width: 10px;
                        left: 10px;
                        bottom: 0;
                    }
                }

                .resident-item {
                    padding: 18px 16px;
                    background: linear-gradient(360deg,
                            rgba(34, 62, 97, 0.8) 0%,
                            rgba(25, 37, 62, 0.08) 100%);
                    border-radius: 8px;
                }
            }

            .resident-record {
                .main-title {
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                    color: #ffffff;
                    text-shadow: 0px 2px 8px rgba(92, 225, 255, 0.4);
                }

                .record-list {
                    background: rgba(78, 90, 105, 0.12);
                    border-radius: 4px;
                    padding: 20px 24px;
                    margin-bottom: 12px;

                    .record-item {
                        position: relative;
                        margin-bottom: 8px;

                        .item-tail {
                            position: absolute;
                            top: 20px;
                            left: 9px;
                            height: calc(100% + 1px);
                            border-left: 1px solid #5ce1ff;
                        }

                        .record-time {
                            margin-bottom: 16px;

                            .time {
                                font-size: 18px;
                                font-family: AlibabaPuHuiTi-Medium,
                                    AlibabaPuHuiTi;
                                font-weight: 500;
                                color: #5ce1ff;
                                background: linear-gradient(180deg,
                                        #ffffff 0%,
                                        #25a7ff 100%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                margin-right: 16px;
                            }

                            .dot-img {
                                margin-right: 16px;
                                width: 20px;
                                display: inline-block;
                            }
                        }

                        .record-content {
                            font-size: 16px;
                            color: #ffffff;
                            background: linear-gradient(360deg,
                                    rgba(34, 62, 97, 0.8) 0%,
                                    rgba(25, 37, 62, 0.08) 100%);
                            border-radius: 4px;
                            margin-left: 38px;
                            padding: 16px;

                            .record-row {
                                margin-bottom: 8px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }

                            .checkUser {
                                font-size: 14px;
                                font-weight: 600;
                                color: #d0deee;
                                line-height: 20px;
                                margin-left: 16px;
                            }
                        }
                    }
                }
            }
        }

        .task-row {
            font-size: 14px;
            color: #fff;
            margin-bottom: 6px;

            .row-item {
                display: inline-block;
                width: 33.3%;
            }

            .row-item1 {
                display: inline-block;
                width: 100%;
            }

            .row-item2 {
                display: inline-block;
                width: 66.6%;
            }

            .row-item4 {
                display: inline-block;
                width: 25%;
            }
        }

        .item-box {
            padding: 16px;
            margin-bottom: 16px;
            border: 1px solid;
            border-image: linear-gradient(180deg,
                    rgba(37, 167, 255, 0.24),
                    rgba(11, 192, 224, 0.24)) 1 1;
            border-radius: 8px;

            .chengzu-label {
                background: rgba(128, 128, 128, 0.2);
                border-radius: 2px;
                padding: 4px 8px;
                margin-left: 10px;
                font-size: 14px;
                color: #cccccc;
            }

            .report-status {
                padding: 4px 8px;
                border-radius: 4px;
                font-size: 14px;
                margin-left: 8px;
            }

            .status1 {
                background: rgba(254, 185, 3, 0.12);
                color: #feb903;
            }

            .status2 {
                background: rgba(92, 255, 181, 0.2);
                color: #5cffb5;
            }

            .status3 {
                background: rgba(128, 128, 128, 0.2);
                color: #cccccc;
            }

            .status4 {
                background: rgba(255, 122, 122, 0.1);
                color: #ff7a7a;
            }

            .check-detail {
                margin-left: 35px;
                font-size: 14px;
                cursor: pointer;

                .icons {
                    width: 14px;
                    display: inline-block;
                }

                .icon-text {
                    // position: relative;
                    // top: -2px;
                    color: #cccccc;
                    font-weight: 500;
                    font-family: PingFangSC-Medium, PingFang SC;
                    background: linear-gradient(180deg,
                            #ddeaf8 0%,
                            #26b6fb 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-left: 8px;
                    margin-right: 8px;
                }
            }

            .go-icon {
                margin-right: 8px;
            }

            .live-item {
                padding: 8px 10px;
                background: linear-gradient(360deg,
                        rgba(34, 62, 97, 0.8) 0%,
                        rgba(25, 37, 62, 0.08) 100%);
                border-radius: 4px;
            }
            .pxjlTitle{
                margin: 8px 0 !important;
            }

            .row-tips {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.65);
                margin-top: 6px;
                .num{
                    font-weight: bold;
                    color: #fff;
                }
            }

            .ListModal {
                margin-top: 16px;

                .modalContent {
                    li {
                        line-height: 48px;
                        cursor: pointer;
                        display: flex;
                        padding-left: 16px;

                        &:nth-child(even) {
                            background: rgba(78, 90, 105, 0.4);
                        }

                        >span {
                            flex: 1;
                            padding: 0 8px;
                        }

                        &:hover {
                            box-shadow: inset 0px 0px 16px 0px rgba(40, 167, 255, 0.8);
                        }

                    }
                }
            }

            .item-title {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 12px;
                color: #ffffff;
                background: linear-gradient(180deg, #ffffff 0%, #7ee7ff 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .subTitle {
                font-size: 16px;
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                color: #ffffff;
                line-height: 24px;
                background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 6px;
            }

            .sec-title {
                font-size: 14px;
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                color: #ffffff;
                background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 6px;
            }

            .t-table {
                margin-bottom: 16px;

                .t-head {
                    display: flex;
                    line-height: 24px;
                    color: #ccc;
                }

                .t-list {
                    display: flex;
                    line-height: 24px;
                }

                .col1 {
                    width: 20%;
                }

                .col2 {
                    width: 60%;
                }
            }

            .item-sub {
                font-size: 16px;
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 500;
                color: #ffffff;
                line-height: 32px;
                background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 10px;
            }

            .check-more {
                text-align: center;
                padding-top: 12px;
                font-size: 14px;
                cursor: pointer;

                .drop-img {
                    vertical-align: middle;
                    margin-left: 4px;
                    display: inline-block;
                }

                .up-img {
                    vertical-align: middle;
                    margin-left: 4px;
                    transform: rotate(180deg);
                    display: inline-block;
                }
            }

            .task-title {
                margin-bottom: 10px;
            }

            .task-active {
                background: linear-gradient(180deg, #ffffff 0%, #7ee7ff 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .task-label {
                margin-right: 30px;
                font-size: 16px;
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 500;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                .task-num {
                    margin-left: 10px;
                    font-family: D-DIN-PRO-Bold, D-DIN-PRO;
                    font-weight: bold;
                    color: #ffffff;
                    line-height: 20px;
                    background: linear-gradient(180deg,
                            #ffffff 0%,
                            #25a7ff 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .task-top-row {
                margin-bottom: 8px;

                .task-top {
                    font-size: 16px;
                    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                    font-weight: 500;
                    color: #ffffff;
                    background: linear-gradient(180deg,
                            #fdffff 0%,
                            #6ab3f5 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .delay-text {
                    font-size: 14px;
                    margin-left: 6px;
                    color: #ff7a7a;
                }

                .todo-label {
                    padding: 4px 8px;
                    background: rgba(254, 185, 3, 0.12);
                    border-radius: 2px;
                    font-size: 14px;
                    color: #feb903;
                    margin-left: 8px;
                }

                .done-label {
                    padding: 4px 8px;
                    background: rgba(92, 255, 181, 0.2);
                    border-radius: 2px;
                    font-size: 14px;
                    color: #10df9d;
                    margin-left: 8px;
                }

                .pend-label {
                    padding: 4px 8px;
                    background: rgba(128, 128, 128, 0.2);
                    border-radius: 2px;
                    font-size: 14px;
                    color: #ffffff;
                    margin-left: 8px;
                }

                .red-label {
                    padding: 4px 8px;
                    background: rgba(255, 122, 122, 0.1);
                    border-radius: 2px;
                    font-size: 14px;
                    color: #ff7a7a;
                    margin-left: 8px;
                }
            }

            .border-item {
                background: linear-gradient(360deg,
                        rgba(34, 62, 97, 0.8) 0%,
                        rgba(25, 37, 62, 0.08) 100%);
                border-radius: 8px;
                padding: 18px 16px;
            }
        }

        /*滚动条样式*/
        &::-webkit-scrollbar {
            width: 0;
            /*height: 4px;*/
        }

        // &::-webkit-scrollbar-thumb {
        //     border-radius: 10px;
        //     -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
        //     background: rgba(255, 255, 255, 0.6);
        // }
        // &::-webkit-scrollbar-track {
        //     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //     border-radius: 0;
        //     background: rgba(0, 0, 0, 0.1);
        // }
    }
}