.wrapper-box-old {
    background: rgba(51, 139, 255, .08);
    border: 1px solid rgba(102,225,223,.4);
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .wrapper-title{
        height: 40px;
        padding-left: 16px;
        background: linear-gradient(270deg, rgba(102,202,225,0.16) 0%, rgba(102,202,225,0.4) 100%)
    }
    .children{
        padding: 16px;
        // box-shadow: inset 0px 0px 16px 0px rgba(116,224,255, 0.4);

    }

    .angle {
        background: url("/assets/map/1.png") no-repeat;
        width: 32px;
        height: 32px;
        position: absolute;
    }
    .angle-1 {
        transform: translate(-13px, -13px) scale(0.5);
        left: 0;
        top: 0;
    }
    .angle-2 {
        transform: translate(13px, -13px) scale(0.5) rotate(90deg);
        right: 0;
        top: 0;
    }
    .angle-3 {
        transform: translate(13px, 13px) scale(0.5) rotate(180deg);
        right: 0;
        bottom: 0;
    }
    .angle-4 {
        transform: translate(-13px, 13px) scale(0.5) rotate(-90deg);
        left: 0;
        bottom: 0;
    }
}
