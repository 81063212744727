.container {
    padding: 6px 6px 0 12px;
    .trigger {
        cursor: pointer;
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 28px;
            .title {
                width: 56px;
                font-size: 14px;
                text-align: left;
            }
            .mes {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 12px;
                > div {
                    &:nth-child(1) {
                        font-size: 20px;
                        font-family: D-DIN-PRO-Medium;
                        background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        margin-right: 4px;
                    }
                    &:nth-child(2) {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        img {
                            height: 14px;
                            width: 14px;
                        }
                        div {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .on {
            color: #5CE1FF;
            min-width: 84px;
        }
        // 负数的颜色
        .down {
            color: #10df9d;
        }
        // 持平的颜色
        .none {
            background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
