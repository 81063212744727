.warningList {
    background: rgba(5, 25, 41, 0.95);
    border-radius: 4px;
    backdrop-filter: blur(2px);
    width: 900px;
    height: 950px;
    position: fixed;
    top: 93px;
    right: 24.5vw;
    z-index: 10;

    .close-img {
        position: absolute;
        top: 12px;
        right: 16px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 2;
    }

    .head {
        width: 100%;
        height: 48px;
        background: #074E9E;
        border-radius: 8px 8px 0px 0px;
        backdrop-filter: blur(4px);

        >div {
            background-image: linear-gradient(180deg, #FFFFFF 0%, #7EE7FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .types {
        width: 100%;
        padding: 16px;

        .item {
            background: #074E9E;
            border: 1px solid rgba(37, 167, 255, 0.6);
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
            color: #A3DAFE;
            line-height: 40px;
            text-align: center;
            border-radius: 2px;
            font-size: 16px;
            cursor: pointer;

            &:not(:last-of-type) {
                margin-right: 26px;
            }

            &.active {
                background: #074E9E;
                box-shadow: inset 0px 0px 16px 0px rgb(37 167 255 / 80%);
                border: 1px solid rgba(37, 167, 255, 0.6);
                color: #FFFFFF;
            }

        }
    }

    .list {
        overflow-y: auto;

        /*滚动条样式*/
        &::-webkit-scrollbar {
            width: 4px;
            /*height: 4px;*/
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
            background: rgba(255, 255, 255, 0.6);
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
        }

        .item:hover {
            background: rgba(5, 43, 62, 0.8);
            box-shadow: inset 0px 0px 16px 0px rgba(40, 167, 255, 0.8);
            backdrop-filter: blur(4px);
        }

        .item:nth-of-type(even) {
            background: rgba(78, 90, 105, 0.4);
        }
    }
}