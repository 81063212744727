@import "antd/dist/antd.css";

.g2-tooltip-list {
    line-height: 3px;
}

// 阿里巴巴普惠
@font-face {
    font-family: AlibabaPuHuiTi-Regular;
    src: url("assets/fonts/Alibaba-PuHuiTi-Regular.ttf");
}

@font-face {
    font-family: AlibabaPuHuiTi-Medium;
    src: url("assets/fonts/Alibaba-PuHuiTi-Medium.ttf");
}

@font-face {
    font-family: AlibabaPuHuiTi-Bold;
    src: url("assets/fonts/Alibaba-PuHuiTi-Bold.ttf");
}

// 电子表字体
@font-face {
    font-family: DS-DIGIB;
    src: url("./assets/fonts/DS-DIGIB.ttf");
}

// 数字字体
@font-face {
    font-family: D-DIN-PRO-Regular;
    src: url("./assets/D-DIN-PRO-400-Regular.ttf");
}

@font-face {
    font-family: D-DIN-PRO-Medium;
    src: url("./assets/D-DIN-PRO-500-Medium.ttf");
}

@font-face {
    font-family: D-DIN-PRO-Bold;
    src: url("./assets/D-DIN-PRO-700-Bold.ttf");
}

/* 总标题 */
@font-face {
    font-family: YouSheBiaoTiHei;
    src: url("./assets/YouSheBiaoTiHei-2.ttf");
}

* {
    box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px rgba(225, 233, 255, 0) inset !important; //设置为透明色时不要写transparent,否则不生效，可自行调整其他颜色

    -webkit-text-fill-color: #fff !important; //字体颜色
    transition-delay: 99999s;
    transition: color 99999s ease-out, background-color 99999s ease-out;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: AlibabaPuHuiTi-Regular;
    background-color: #0b1c2c;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0;
}

input,
button {
    border: none;
    outline: none;
}

ul,
li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.t-nf {
    font-family: D-DIN-PRO-Bold;
}

.t-Md {
    font-family: D-DIN-PRO-Medium;
}

.fm-aph-r {
    font-family: AlibabaPuHuiTi-Regular;
}

.fm-aph-m {
    font-family: AlibabaPuHuiTi-Medium;
}

.fm-aph-b {
    font-family: AlibabaPuHuiTi-Bold;
}

.fm-ds-d {
    font-family: DS-DIGIB;
}

.fm-d-r {
    font-family: D-DIN-PRO-Regular;
}

.fm-ysbth {
    font-family: YouSheBiaoTiHei;
}

/* 通用样式 */

// margin
.mt-auto {
    margin-top: auto;
}

// height
.height-full {
    height: 100%;
}

@for $i from 1 to 101 {
    .height-#{$i} {
        height: #{$i}px;
    }
}

// width
.width-full {
    width: 100%;
}

.span-25 {
    width: 25%;
}

.span-30 {
    width: 30%;
}

.span-33 {
    width: 33%;
}

.span-50 {
    width: 50%;
}

@for $i from 1 through 101 {
    .width-#{$i} {
        width: #{$i};
    }
}

/* flex */
.flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.fd-c {
    flex-direction: column;
}

.fw-w {
    flex-wrap: wrap;
}

.jc-b {
    justify-content: space-between;
}

.jc-a {
    justify-content: space-around;
}

.jc-e {
    justify-content: flex-end;
}

.jc-c {
    justify-content: center;
}

.ai-c {
    align-items: center;
}

.ai-e {
    align-items: end;
}

.ai-f {
    align-items: flex-start;
}

/* 字体 */

.fw-b {
    font-weight: bold;
}

@for $i from 5 to 25 {
    .fs-#{$i*2} {
        font-size: #{$i * 2}px;
    }
}

/* 文字颜色 */
.color-1 {
    color: #fff;
}

.color-2 {
    color: #d0deee;
}

.color-3 {
    color: #6c8097;
}

.color-primary {
    color: #338bff;
}

.color-orange {
    color: #ff711f;
}

.color-FF7A7A {
    color: #ff7a7a;
}

.color-FF8F61 {
    color: #ff8f61;
}

.color-4C99FF {
    color: #5ce1ff;
}

.color-6C8097 {
    color: #6c8097;
}

.color-6C8097 {
    color: #6c8097;
}

.color-FFC97A {
    color: #ffc97a;
}

.color-FF711F {
    color: #ff711f;
}

.color-FFAB30 {
    color: #ffab30;
}

.color-FF3838 {
    color: #ff3838;
}

.color-4C99FF {
    color: #4C99FF;
}

.color-421A02 {
    color: #421a02;
}

.color-470000 {
    color: #470000;
}

.color-382800 {
    color: #382800;
}

.color-10DF9D {
    color: #10df9d;
}

.color-A4DAFE {
    color: #a4dafe;
}

.color-10DF9D {
    color: #10df9d;
}

// 文本
.t-center {
    text-align: center;
}

// 渐变字体颜色
.t-color {
    background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

// background
.bg-primary {
    background-color: #338bff;
}

.bg-orange {
    background-color: #ff711f;
}

// line-height
@for $i from 5 to 30 {
    .lh-#{$i*2} {
        line-height: #{$i * 2}px;
    }
}

// border
.br-2 {
    border-radius: 2px;
}

.card-title {
    height: 18px;
    font-size: 18px;
    font-family: PangMenZhengDao;
    color: #ffffff;
    line-height: 21px;
}

// overflow
.fl-r {
    float: right;
}

.fl-l {
    float: left;
}

.of-h {
    overflow: hidden;
    position: absolute;
}

.trigger {
    cursor: pointer;
}

#root {
    color: #fff;
    height: 100%;
    width: 100%;
}

// 盒子居中
.box-center {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

// line
.line {
    height: 1px;
    background-color: #6c8097;
}

.overflow-y {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.hover {
    &:hover {
        background-color: rgba(78, 90, 105, 0.4);
    }
}

// 模态框hover
.m-hover {
    &:hover {
        background: rgba(5, 43, 62, 0.8);
        box-shadow: inset 0px 0px 16px 0px rgba(40, 167, 255, 0.8);
    }
}

.text-ellipesis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// 天气
#he-plugin-simple-wrap {
    width: 100px;
    position: fixed;
    top: 4px;
    right: calc(142px - 6px);
    z-index: 99;

    .s-sticker-tmp {

        font-family: D-DIN-PRO-SemiBold, D-DIN-PRO !important;
    }

    .s-sticker-alarm {
        display: none !important;
    }

    .s-sticker-qlty {
        display: none !important;
    }

    .s-sticker {
        &> :last-child {
            display: none;
        }
    }
}

// map
.l7-popup-anchor-bottom .l7-popup-tip {
    border-top-color: rgba(5, 24, 50, 0.8);
}

.l7-popup-content {
    background-color: rgba(5, 24, 50, 0.8) !important;
}

.hiden-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
}