.extra-title {
    font-size: 14px;
    font-weight: 400;

    >div {
        border: 1px solid rgba(37, 167, 255, 0.6);
        cursor: pointer;
    }

    .active {
        background: #074E9E;
        box-shadow: inset 0px 0px 16px 0px rgba(37, 167, 255, 0.8);
    }
}
.g2-tooltip{
    line-height: 0px !important;
}