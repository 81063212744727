.map {
    .lark-map {
        .marker {
            >div {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 8px;
                width: 100%;
                height: 32px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #c8fcff;
                cursor: pointer;
                border-radius: 4px;
                background: rgba(0, 64, 158, 0.24);
                border: 1px solid #6699E1;
            }
        }

        .marker-popup {
            .marker-popup-box {

                z-index: 9;
                border-radius: 4px;
                width: 158px;
                // max-width: 160px;
                // min-width: 160px;
                background: #092552;
                // backdrop-filter: blur(12px);
                border: 1px solid #6699E1;

                >div {
                    &:nth-child(1) {
                        position: relative;
                        line-height: 32px;
                        text-align: center;
                        padding: 0 8px;
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                        }
                    }

                    &:nth-child(2) {
                        .color-text {
                            font-size: 20px;
                            font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
                            font-weight: 600;
                            color: #fff;
                            line-height: 32px;
                            margin-left: auto;
                            margin-top: -2px;
                            background: linear-gradient(180deg, #FFFFFF 0%, #FF7167 100%);
                            -webkit-background-clip: text;
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
            }
            >img{
                z-index: -1;
            }
        }
    }

    .operations {
        position: absolute;
        z-index: 10;
        top: 16px;
        display: flex;
        justify-content: center;
        width: 100%;

        .item {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 160px;
            background-color: rgba(5, 62, 61, 0.16);
            backdrop-filter: blur(2px);

            .item-content {
                display: flex;
                padding: 14px 16px;
                align-items: flex-end;
                // position: relative;
                bottom: 6px;

                .icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }

                span {
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #5ce1ff;
                    line-height: 20px;
                }

                .number {
                    // height: 28px;
                    font-size: 24px;
                    font-family: Helvetica-Bold, Helvetica;
                    font-weight: bold;
                    color: #ffffff;
                    line-height: 20px;
                    margin-left: 8px;
                }

                .dropdown-icon {
                    width: 16px;
                    height: 16px;
                    margin-left: 8px;

                    &.opend {
                        transform: rotateX(180deg);
                    }
                }
            }

            .options {
                position: absolute;
                top: 48px;
                padding-top: 8px;
                max-height: 368px;
                width: 160px;
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    display: none;
                }

                .option {
                    display: flex;
                    width: 160px;
                    box-sizing: border-box;
                    height: 36px;
                    // border-right: none;
                    border-top: none;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 12px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    background: rgba(5, 43, 62, 0.8);
                    border: 1px solid rgba(37, 167, 255, 0.6);

                    &.hover {
                        background: rgba(5, 43, 62, 0.8);
                        box-shadow: inset 0px 0px 16px 0px rgba(40, 167, 255, 0.8);
                        border: 1px solid rgba(37, 167, 255, 0.6);
                        color: white;
                    }

                    &:first-child {
                        border-top: 1px solid rgba(102, 225, 223, 0.6);
                    }
                }
            }
        }
    }
}

.l7-popup-content {
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
}

.l7-marker {
    &:hover {
        z-index: 9999;
    }
}