.wrapper {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 48px;
        line-height: 48px;
        font-size: 22px;
        background: linear-gradient(90deg, #004c9a 0%, #10253f 100%);
        padding: 0 16px;
        border-radius: 8px 8px 0 0;
        font-family: AlibabaPuHuiTi-Medium;

        .icon {
            height: 28px;
            width: 28px;
            margin-right: 8px;
        }
        .sub-title {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            line-height: 28px;
            margin-left: 16px;
        }
    }
    .titleBlock{
        width: 100%;
        height: 64px;
        font-size: 18px;
        background: linear-gradient(90deg, #004c9a 0%, #10253f 100%);
        padding: 0 16px;
        border-radius: 8px 8px 0 0;
        font-family: AlibabaPuHuiTi-Medium;
        .icon {
            height: 24px;
            width: 24px;
            margin-right: 8px;
        }
        .sub-title-block {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            line-height: 20px;
        }

    }
    .children {
        box-sizing: border-box;
        border-radius: 0 0 8px 8px;
        border: 1px solid rgba(37, 167, 255, 0.24);
        border-top: none;
        background: linear-gradient(360deg, rgba(34, 62, 97) 0%, rgba(25, 37, 62) 100%);
        overflow: hidden;
    }
}
