ul,
li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .realTime {
        display: flex;

        >div {
            flex: 1;
            padding: 12px;
            font-size: 14px;

            .count {
                font-family: D-DIN-PRO-Medium;
                background: linear-gradient(180deg, #FFFFFF 0%, #3CA2F8 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                // font-weight: bold;
                font-size: 28px;
                margin-right: 2px;
            }
        }
    }

    .tabs {
        display: flex;
        padding: 0 12px 0 12px;

        >div {
            flex: 1;
            text-align: center;
            border: 1px solid rgba(37, 167, 255, 0.6);
            height: 28px;
            line-height: 28px;
            cursor: pointer;
        }

        .tabActive {
            background: #074E9E;
            box-shadow: inset 0px 0px 16px 0px rgba(37, 167, 255, 0.8);
        }
    }

    .title {
        color: #A4DAFE;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 7px 12px;
        background: linear-gradient(360deg, rgba(34, 62, 97, 0.8) 0%, rgba(25, 37, 62, 0.08) 100%);
        span{
            &:nth-child(1){
                width: 140px;
            }
            &:nth-child(2){
                width: 208px;
                padding-left: 6px;
            }
            &:nth-child(3){
                width: 110px;
            }
        }
    }

    .itemContainer {
        height: 1px;
        flex-grow: 1;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding: 7px 12px;
            cursor: pointer;

            &:hover {
                background: rgba(78, 90, 105, 0.4);
            }

            span {
                &:nth-child(1) {
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &:nth-child(2) {
                    width: 208px;
                }
                &:nth-child(3) {
                    width: 110px;
                }
            }
        }

        .type {
            color: #338bff;

            &:hover {
                background: rgba(78, 90, 105, 0.4);
            }
        }

        .child {
            height: auto;
        }
    }
}